.unauthorized-ui-img {
  min-width: 500px;
  max-width: 700px;
  height: auto;
}
@media only screen and (max-width: 1000px) {
    .unauthorized-ui-img {
        min-width: 100%;
        max-width: 100%;
        height: auto;
      }
  }
