.image-editor-navigation {
    background: var(--bg-clr1);
    height: 84px;
    border-top: solid 1px #2b2a30;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }
  .image-editor-navigation__button {
    margin-left: 8px;
    margin-right: 8px;
  }
  .image-editor-navigation__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-editor-navigation__upload-input {
    display: none;
  }
  @media (max-width: 540px) {
    .image-editor-navigation {
      padding-left: 8px;
      padding-right: 8px;
    }
    .image-editor-navigation__button {
      margin-left: 4px;
      margin-right: 4px;
    }
  }