.auth-container:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/img/homepage-dots-p-1600.png");
}

.ornament_digital-atom {
  position: sticky;
}

.ornament_digital-atom.pos-left {
  top: 69%;
  left: 23%;
  scale: 0.69;
}
.ornament_digital-atom.pos-right {
  bottom: 69%;
  left: 69%
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--auth-linear-bg);
}

.login-text {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  user-select: none;
}

.note {
  margin-top: 20px;
  padding: 0 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.oauth-form {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  color: #ffffff;
  background-color: #242424;
  gap: 5rem;
  border-radius: 8px;
  box-shadow: 0px 1px 15px rgba(199, 199, 199, 0.5);
}
.oauth-logo {
  width: 100px;
  border-radius: 5px;
}
